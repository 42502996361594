//↗ → ← ↖

@mixin link-arrow() {
    --pos: 0;
    --pos-hover: #{rem(116)};
    --ease: var(--ease-out-quad);
    --time: .5s;

    @media (max-width: $smartphone) {
        --pos-hover: #{rem(48)};
    }

    @include basic-a();

    display: block;
    position: relative;
    overflow: hidden;
    padding-right: var(--pos-hover);

    > svg {
        display: block;
        position: absolute;
        top: 0;
        left: calc(var(--pos-hover) * -1);
        width: var(--pos-hover);
        height: 100%;
        transform: translateX(var(--pos));
        transition: transform var(--time) var(--ease);
    }

    > span {
        display: block;
        transform: translateX(calc(var(--pos-hover) + var(--padding-s)));
        transition: transform var(--time) var(--ease);
    }

    &.--active,
    &.__link-active {
        --pos: var(--pos-hover);
        // pointer-events: none;
    }

    /// TOUCH
    @include isTouch() {
        --pos: var(--pos-hover);
    }

    @media (max-width: $smartphone) {
        > span {
            transform: translateX(calc(var(--pos-hover) + var(--padding-xs)));
        }
    }
}

.link-arrow {
    @include link-arrow();
}
