.block-locations {
    --font-size: var(--font-size-large);
    --padding-v: var(--padding-xxl);
    --padding-h: var(--padding-xxxxl);

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xxlarge);
        --padding-v: var(--padding-xl);
        --padding-h: 0;
    }
}

.block-locations {
    font-size: var(--font-size);
    overflow: hidden;
    padding: var(--padding-v) var(--padding-h);

    @include font-sans-light();

    .location-card {
        position: relative;

        &__image {
            > div { height: 100%; }

            overflow: hidden;

            .media-holder {
                transform: scale3d(1.5, 1.5, 1);
            }

            @include z-index($z-index-bg);
        }

        &__text {
            background: var(--blue);
            bottom: 0;
            color: var(--white);
            left: 0;
            padding: var(--padding-s);
            position: absolute;
            width: 100%;

            @include z-index($z-index-3D);
        }
    }

    @media (max-width: $smartphone) {
        &__list.--desktop { display: none; }
        li:not(:last-child) { margin-bottom: var(--padding-l); }
        .location-card__text { text-align: center; }
    }

    @media (min-width: $smartphone) {
        display: flex;
        padding-bottom: var(--padding-xxxl);

        > * { width: calc(50% - (var(--padding-s) / 2)); }

        > *:last-child {
            margin-left: var(--padding-s);
            margin-top: var(--padding-xxl);
        }

        &__list.--mobile { display: none; }
        li:not(:last-child) { margin-bottom: var(--padding-s); }
    }
}
