.section-title {
    --font-size-title: var(--font-size-xlarge);

    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-xxlarge);
    }

    @include font-sans-light(1);
}

.section-title {
    font-size: var(--font-size-title);
    height: calc(var(--font-size-title) * 1.1);
    overflow: hidden;
    position: relative;

    &.--double {
        height: calc(var(--font-size-title) * 2);
    }

    span {
        display: block;
        position: relative;
        transform: translate3d(0, 110%, 0);
    }
}
