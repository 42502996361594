.block-key-benefits {
    --font-size-title: var(--font-size-xlarge);
    --font-size: var(--font-size-mid);
    --font-size-tab: var(--font-size-base-l);
    --padding-v: var(--padding-xxxl);
    --padding-h: var(--padding-xxxxl);

    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-xxlarge);
        --font-size: var(--font-size-xlarge);
        --font-size-tab: var(--font-size-xlarge);
        --padding-v: var(--padding-xxl);
        --padding-h: var(--padding-s);
    }
}

.block-key-benefits {
    font-size: var(--font-size);
    overflow: hidden;
    padding: var(--padding-v) var(--padding-h);

    @include font-sans();

    &__header {
        max-width: rem(880);
    }

    h2 {
        font-size: var(--font-size-title);
        margin: 0 0 var(--padding-m);

        @include font-sans();
    }

    &__text {
        * {
            margin: 0;

            &:not(:last-child) { margin: 0 0 var(--padding-s); }
        }

        i,
        em {
            @include font-serif-italic(0.95);
        }
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        font-size: var(--font-size-tab);
        margin-top: var(--padding-l);

        @include font-sans(1);
    }

    &__item {
        border: 1px solid var(--black);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: var(--padding-xxs);

        @include isCursor() {
            .tab-slider .text {
                transition: transform .8s var(--ease-out-quart);
            }
        }
    }

    .tab-slider { margin-top: var(--padding-xs); }

    @include isCursor() {
        &__item:hover .tab-slider .text {
            transform: translate3d(0, 0, 0) !important;
        }
    }

    @media (max-width: $smartphone) {
        &__item {
            min-height: var(--padding-xxxxl);
            width: calc(50% - (var(--padding-s) / 2));

            &:nth-child(even) { margin-left: var(--padding-s); }
            &:not(:last-child) { margin-bottom: var(--padding-s); }
        }
    }

    @media (min-width: $smartphone) {
        &__item {
            width: calc(20% - (var(--padding-xs) / 5 * 4));

            &:not(:first-child) { margin-left: var(--padding-xs); }
        }
    }
}