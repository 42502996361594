.block-dream-job {
    --content-max-width: #{620px / 16px * 1rem};
    --padding-v: var(--padding-xxl);
    --padding-h: 8%;
    --font-size-big: var(--font-size-xxxlarge);
    --font-size: var(--font-size-large);
    --font-size-small: var(--font-size-mid);

    @media (max-width: $smartphone) {
        --font-size-big: var(--font-size-xxlarge2);
        --font-size: var(--font-size-xxlarge);
        --padding-v: var(--padding-xxxl);
        --padding-h: var(--padding-s);
    }
}

.block-dream-job {
    background: var(--blue);
    color: var(--white);
    overflow: hidden;
    padding: var(--padding-v) var(--padding-h);
    position: relative;
    text-align: center;

    @include font-sans();

    &__content {
        margin: 0 auto;
        max-width: var(--content-max-width);
        position: relative;
        z-index: 1;
    }

    &__number {
        font-size: var(--font-size-big);
        margin: 0;

        @include font-sans-bold();
    }

    &__text {
        font-size: var(--font-size);

        p {
            margin: 0 0 var(--padding-s);

            strong,
            em {
                @include font-serif-italic(0.95);
            }
        }
    }

    &__support-text {
        font-size: var(--font-size-small);
        margin: 0;
    }

    &__logo {
        left: 50%;
        position: absolute;
        pointer-events: none;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        z-index: 0;

        svg {
            height: auto;
            width: 100%;
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        > div:not(.active) { display: none; }
    }

    @media (max-width: $smartphone) {
        &__logo {
            width: rem(300);
        }

        &__wrapper > div:last-child {
            margin-bottom: 0;
        }
    }

    @media (min-width: $smartphone) {
        &__logo {
            width: rem(500);
        }
    }
}
