#Footer {
    --font-size: var(--font-size-base);
    --bg: var(--blue);
    --color: var(--white);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-l);
    --font-size: var(--font-size-base);

    @media (max-width: $smartphone) {
        --padding-h: var(--padding-s);
        --padding-v: var(--padding-s);
    }
}

#Footer {
    background: var(--bg);
    color: var(--color);
    font-size: var(--font-size);
    padding: var(--padding-v) var(--padding-h);

    @include font-sans();

    p { margin: 0 0 var(--padding-s); }

    .partners {
        display: flex;
        flex-wrap: wrap;

        a { display: block; }
        li { width: 12%; }
    }

    @media (max-width: $smartphone) {
        > div:last-child {
            margin-top: var(--padding-l);
        }

        .partners {
            flex-wrap: wrap;
            justify-content: flex-start;

            li:nth-child(n + 5) { margin-top: var(--padding-s); }
            li:not(:nth-child(4n)) { margin-right: 13.333%; }
        }
    }

    @media (min-width: $smartphone) {
        display: flex;
        justify-content: space-between;

        > div:first-child {
            width: 30%;

            svg {
                height: auto;
                width: rem(220);
            }
        }

        > div:last-child { width: 70%; }

        .partners {
            justify-content: space-between;
            width: 100%;
        }
    }
}
