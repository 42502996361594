.block-program-cards {
    --font-size-big: var(--font-size-xxlarge1);
    --font-size: var(--font-size-mid);
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-h: var(--padding-xxxxl);
    --card-padding: var(--padding-s);
    --header-height: calc((var(--font-size-big) + var(--card-padding)) * 2);

    @media (max-width: $smartphone) {
        --font-size-big: var(--font-size-xxlarge);
        --font-size: var(--font-size-xlarge);
        --padding-top: 0;
        --padding-bottom: var(--padding-xl);
        --padding-h: 0;
    }
}

.block-program-cards {
    overflow: hidden;
    padding: var(--padding-top) var(--padding-h) var(--padding-bottom);

    .program-hover-card {
        overflow-y: hidden;
        padding-bottom: 76%;
        position: relative;

        &__content,
        &__image,
        .tint {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        .tint {
            background: var(--blue);
            mix-blend-mode: multiply;
            opacity: 0;
            transition: opacity .3s var(--ease-out-quad);

            @include z-index($z-index-3D);
        }

        &__image {
            overflow: hidden;

            > div,
            .media-holder {
                height: 100%;
            }

            .media-holder {
                padding-top: 0;
                transform: scale3d(1.5, 1.5, 1);

                img { object-fit: cover; }
            }

            @include z-index($z-index-bg);
        }

        &__content {
            @include z-index($z-index-wrap);

            background: var(--blue);
            color: var(--white);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            transform: translate3d(0, calc(100% - var(--header-height)), 2px);
            transition: transform .3s var(--ease-out-quad), background .1s var(--ease-out-quad);

            h3,
            p {
                margin: 0;
            }

            h3 {
                align-items: flex-end;
                display: flex;
                font-size: var(--font-size-big);
                height: var(--header-height);
                padding: var(--padding-s);
                width: 100%;

                @include font-sans-light(1);

                span {
                    display: block;
                    width: 100%;
                }
            }

            p {
                font-size: var(--font-size);
                padding: 0 var(--padding-s) var(--padding-s);
                text-align: left;

                @include font-sans-light();
            }
        }

        &:hover  {
            .tint { opacity: 1; }

            .program-hover-card__content {
                background: transparent;
                transform: translate3d(0, 0, 1px);
            }
        }
    }

    @media (max-width: $smartphone) {
        &__header {
            margin-bottom: var(--padding-l);
            text-align: center;

            .arrow-mobile { margin-bottom: var(--padding-l); }
            .arrow-desktop { display: none; }
        }

        li:not(:last-child) { margin-bottom: var(--padding-l); }
        .program-hover-card__content {
            h3 { text-align: center; }
        }

        &__list.--desktop { display: none; }
    }

    @media (min-width: $smartphone) {
        display: flex;

        > * { width: calc(50% - (var(--padding-s) / 2)); }
        > *:last-child { margin-left: var(--padding-s); }

        &__header {
            padding-bottom: 76%;
            position: relative;

            > div {
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: flex-end;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }

            h2 { margin: 0 0 var(--padding-s); }
            .arrow-mobile { display: none; }

            svg {
                height: auto;
                width: rem(360);
            }
        }

        &__list.--mobile { display: none; }
        &__list:last-child { margin-top: var(--padding-xxxxxl); }
        li:not(:last-child) { margin-bottom: var(--padding-s); }
    }
}
