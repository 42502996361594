.block-marquee {
    --font-size: var(--font-size-base);
    --color: var(--blue);
    --padding-v: var(--padding-xs);
    --padding-h: 0;

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xxxlarge);
        --padding-v: 0;
    }
}

.block-marquee {
    color: var(--color);
    font-size: var(--font-size);
    overflow: hidden;
    padding: var(--padding-v) var(--padding-h);
    text-transform: uppercase;

    @include font-sans();

    &__carousel {
        flex-grow: 1;
        overflow: hidden;
        width: 100vw;
    }
}