.program-card {
    --font-size-overlay-big: var(--font-size-xlarge);
    --font-size-overlay: var(--font-size-mid-l);
    --font-size-overlay-small: var(--font-size-mid);
    --font-size-big: var(--font-size-mid-l);
    --font-size: var(--font-size-base-l);

    @media (max-width: $smartphone) {
        --font-size-big: var(--font-size-xlarge);
        --font-size: var(--font-size-base);
    }
}

.program-card {
    display: block;
    overflow: hidden;
    padding-bottom: 75%;
    position: relative;
    width: 100%;

    @include font-sans();
    @include basic-a();

    > a {
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__image,
    &__content,
    &__overlay {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
    }

    &__image {
        height: 100%;
        overflow: hidden;

        .media-holder {
            height: 100%;

            img { object-fit: cover; }
        }
    }

    &__content,
    &__overlay {
        background: var(--blue);
        padding: var(--padding-m) var(--padding-s);

        .title,
        .subtitle { margin: 0; }

        .title { font-weight: 400; }
    }

    &__content {
        color: var(--white);
        text-align: right;
        transition: transform .3s var(--ease-out-quart) .3s;

        .title {
            font-size: var(--font-size-big);
            font-weight: 400;
        }

        .subtitle {
            font-size: var(--font-size);
        }
    }

    &__overlay {
        bottom: auto;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        top: 100%;
        transition: transform .5s var(--ease-out-quart);

        .title {
            color: var(--black);
            font-size: var(--font-size-overlay-big);
            width: 75%;
        }

        .subtitle {
            color: var(--white);
            font-size: var(--font-size-overlay);
            width: 50%;
        }

        .more-info {
            border: 1px solid currentColor;
            border-radius: var(--padding-m);
            color: var(--black);
            font-size: var(--font-size-overlay-small);
            padding: var(--padding-xxs) var(--padding-s) var(--padding-xxxs) var(--padding-xxs);

            &::before {
                border: 1px solid currentColor;
                border-radius: 50%;
                content: '+';
                display: inline-block;
                height: rem(45);
                line-height: .7;
                margin-right: var(--padding-xxs);
                padding: var(--padding-xxs) var(--padding-xxs) var(--padding-xxxs);
                text-align: center;
                width: rem(45);
                position: relative;
                top: rem(-1.5);
                left: rem(-1.5);
            }
        }

        > div {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
        }
    }

    @include isCursor() {
        &:hover {
            .program-card__content {
                transform: translate3d(0, 100%, 0);
                transition: transform .3s var(--ease-out-quart);
            }

            .program-card__overlay {
                transform: translate3d(0, -100%, 0);
                transition: transform .5s var(--ease-out-quart) .1s;
            }
        }
    }

    @media (max-width: $smartphone) {
        transform: none !important;

        &__content {
            padding: var(--padding-s) var(--padding-xs);
            text-align: center;

            .title {
                margin-bottom: var(--padding-xxs);
            }
        }
    }
}