#Scrollbar,
#slider-scrollbar {
    @include z-index($z-index-cookies);

    height: calc(var(--toggle-width) + #{rem(10)});
    position: absolute;
    pointer-events: none;
    position: absolute;
    right: var(--padding-h);
    top: 50%;
    transform: translate3d(#{rem(5)}, calc(-1 * (var(--toggle-width) + #{rem(10)}) / 2), #{$z-index-cookies + 'px'});
    width: calc(var(--toggle-width) + #{rem(10)});

    svg {
        height: 100%;
        transform: rotate(-90deg);
        width: 100%;

        circle {
            stroke: var(--white);
        }
    }
}
