.block-underline-text {
    --font-size: var(--font-size-mid);
    --padding-v: var(--padding-xxxl);
    --padding-h: var(--padding-l);

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xxlarge);
        --padding-v: var(--padding-xxl);
        --padding-h: var(--padding-s);
    }
}

.block-underline-text {
    font-size: var(--font-size);
    overflow: hidden;
    padding: var(--padding-v) var(--padding-h);

    @include font-sans();

    &__content {
        p {
            margin: 0;

            &:not(:last-child) {
                margin: 0 0 var(--padding-l);
            }
        }

        del {
            position: relative;
            text-decoration: none;
        }
    }

    @media (max-width: $smartphone) {
        svg { display: none; }
    }

    @media (min-width: $smartphone) {
        &__content {
            margin: 0 auto;
            max-width: rem(655);
            text-align: center;

            del svg {
                bottom: -.1em;
                left: 0;
                position: absolute;
                stroke-width: .05em;
                width: 100%;
            }
        }
    }
}
