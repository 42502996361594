.block-billboard {
    --content-max-width: #{700px / 16px * 1rem};
    --padding-v: var(--padding-xxxl);
    --padding-h: 8%;

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-base);
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-s);
    }
}

.block-billboard {
    background: var(--black);
    color: var(--white);
    overflow: hidden;
    padding: calc(var(--header-height) + var(--padding-v)) var(--padding-h) var(--padding-v);

    &__content {
        h1 {
            margin: 0;
            max-width: var(--content-max-width);

            > * { margin: 0; }
            > *:first-child { margin: 0 0 var(--padding-l); }

            strong,
            em {
                @include font-serif-italic(0.95);
            }

            .line {
                height: calc(var(--font-size-xlarge) * 1.25);
                overflow: hidden;
                margin: -0.25em 0;
            }
        }
    }

    &__logo {
        position: absolute;

        svg {
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            circle,
            path {
                stroke-width: 1px;
                stroke: currentColor;
                fill: none;
            }
        }
    }

    @media (max-width: $smartphone) {
        min-height: 90vh;

        &__logo {
            left: -2%;
            overflow: hidden;
            padding-top: 170%;
            top: 55%;
            width: 100%;

            svg {
                width: 170%;
            }
        }

        &__content h1 {
            font-size: var(--font-size-xxlarge);

            .line {
                height: calc(var(--font-size-xxlarge) * 1.25);
            }
        }
    }

    @media (min-width: $smartphone) {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &__logo {
            left: 41%;
            padding-top: 70%;
            top: 20%;
            width: 65%;
        }
    }
}
