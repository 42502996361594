.block-human-pangea,
.pangea-slide {
    --font-size: var(--font-size-base);
    --padding-v: var(--padding-xxl);
    --padding-h: var(--padding-xxxxl);

    @include font-sans();

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-large);
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-s);
    }
}

.block-human-pangea {
    overflow: hidden;
    padding: var(--padding-v) 0;

    &__content svg {
        height: auto;
        width: 100%;
        pointer-events: none;

        polygon {
            pointer-events: all;
        }
    }

    .group {
        &.active .area {
            fill: var(--blue);
        }

        .dot,
        .area { cursor: pointer; }

        .city,
        .line { pointer-events: none; }
    }

    &__header .text {
        max-width: rem(930);
        margin: 0 auto var(--padding-l);
    }

    .arrow-desktop {
        height: rem(222);
        margin: 0 auto;
        width: rem(100);
    }

    @media (max-width: $smartphone) {
        &__header {
            padding: 0 var(--padding-h);

            .arrow-desktop { display: none; }
        }

        &__content {
            margin-bottom: var(--padding-m);
            padding: 0 var(--padding-h);
        }

        &__slider {
            display: flex;
            height: 100%;
            overflow: hidden;
            overflow-x: scroll;
            position: relative;
            scroll-behavior: smooth;
            scroll-snap-type: x mandatory;
            width: auto;

            li {
                min-width: rem(312);
                scroll-snap-align: start;

                &:not(:last-child) { margin-right: var(--padding-s); }
            }


            .pangea-slide { scroll-snap-align: start; }
        }

        &__desktop { display: none; }
    }

    @media (min-width: $smartphone) {
        &__header {
            text-align: center;

            h2 { margin: 0 0 var(--padding-s); }
        }

        &__content {
            max-width: rem(504);
            margin: var(--padding-xxl) auto 0;
        }

        &__slider {
            display: none;
        }

        &__desktop {
            left: 0;
            position: absolute;
            top: 0;
            pointer-events: none;

            @include z-index($z-index-3D);
        }
    }
}

.pangea-slide {
    font-size: var(--font-size);

    &__content {
        background: var(--blue);
        color: var(--white);

        p {
            margin: 0;

            &:not(:last-child) { margin-bottom: var(--padding-xxxs); }
        }
    }

    .media-holder {
        img { object-fit: cover; }
    }

    @media (max-width: $smartphone) {
        width: 100%;

        &__content { padding: var(--padding-s); }
        &__name { margin-bottom: var(--padding-xxs); }

        .media-holder {
            height: 100%;
            width: 100%;
        }
    }

    @media (min-width: $smartphone) {
        align-items: flex-start;
        display: flex;
        height: rem(450);
        justify-content: flex-start;
        left: 0;
        opacity: 0;
        position: absolute;
        pointer-events: none;
        top: 0;
        transition: opacity .2s ease-out;
        visibility: hidden;

        &__content { padding: var(--padding-xs); }
        &__name { display: none; }

        &.visible {
            opacity: 1;
            visibility: visible;
        }

        &__img-wrapper {
            overflow: hidden;
            position: relative;

            > div { transform: scale3d(1.25, 1.25, 1); }

            .media-holder {
                height: rem(450);
                padding-top: 0;
                transform: translate3d(var(--displaceX), 0, 0);
                width: rem(330);
            }
        }

        @include z-index($z-index-wrap);
    }
}
