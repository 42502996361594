.block-university {
    --font-size: var(--font-size-xxlarge);
    --padding-v: var(--padding-xxxl);
    --content-height: #{856px / 16px * 1rem};
    --logo-width: #{388px / 16px * 1rem};

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xxlarge1);
        --padding-v: var(--padding-xxl);
        --content-height: #{300px / 16px * 1rem};
        --logo-width: #{167px / 16px * 1rem};
    }
}

.block-university {
    overflow: hidden;
    padding: var(--padding-v) 0 0;

    &__header,
    &__content {
        text-align: center;
    }

    &__header {
        svg {
            height: auto;
            width: var(--logo-width);
        }

        .words {
            font-size: var(--font-size);
            height: var(--font-size);

            @include font-serif-italic();

            > span { display: none; }
        }
    }

    &__content {
        position: relative;
        height: var(--content-height);
        overflow: hidden;

        > div {
            left: 0;
            position: absolute;
            top: 0;
            transform-origin: center center;
        }

        .ushape {
            height: rem(361);
            width: rem(458);
        }

        .exclamation-body {
            height: rem(91);
            width: rem(357);
        }

        .semicircle {
            height: rem(133);
            width: rem(267);
        }

        .exclamation-circle {
            height: rem(143);
            width: rem(143);
        }

        .dot {
            height: rem(61);
            width: rem(61);
        }

        // .example {
        //     height: rem(250);
        //     width: rem(250);

        //     img {
        //         height: 100%;
        //         width: 100%;
        //     }
        // }

        svg { display: none; }
    }


    @media (max-width: $smartphone) {
        &__content {
            .ushape {
                height: auto;
                width: rem(210);
            }

            .exclamation-body {
                height: auto;
                width: rem(170);
            }

            .semicircle {
                height: auto;
                width: rem(115);
            }

            .exclamation-circle {
                height: auto;
                width: rem(61);
            }

            .dot {
                height: auto;
                width: rem(26);
            }
        }
    }
}