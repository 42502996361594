#Header {
    --width: 100%;
    --height: var(--header-height);
    --color: var(--secondary);
    --bg: var(--primary);
    --padding-v: var(--padding-xxs);
    --padding-h: var(--padding-l);
    --font-size: var(--font-size-base);

    @media (max-width: $smartphone) {
        --padding-h: var(--padding-s);
    }
}

#Header {
    @include z-index($z-index-windows);

    align-items: center;
    color: var(--color);
    display: flex;
    height: var(--height);
    justify-content: space-between;
    padding: var(--padding-v) var(--padding-h);
    position: fixed;
    transform: translate3d(0, var(--y-header), #{$z-index-windows + 'px'});
    transition: all .3s var(--ease-out-quad);
    right: 0;
    top: 0;
    width: var(--width);

    &.__toggle {
        .toggle-menu {
            opacity: 0;
            visibility: hidden;
        }

        .back {
            opacity: 1;
            visibility: visible;
        }
    }

    &.--with-bg { background-color: var(--bg); }

    &::after {
        @include z-index($z-index-wrap);

        background: rgba(var(--whiteRGB), .8);
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        top: 100%;
        transform: translate3d(0, -1px, 2px);
        width: 100%;
    }

    &.__nav-open {
        color: var(--white);

        .toggle-menu svg {
            transform: rotate(-90deg);
        }
    }

    .back {
        opacity: 0;
        position: absolute;
        right: var(--padding-h);
        visibility: hidden;

        @include z-index($z-index-cookies);

        svg {
            height: 100%;
            width: 100%;
        }

        &:hover {
            .circle {
                fill: var(--white);
                stroke: var(--black);
            }

            .line { stroke: var(--black); }

            @include isTouch() {
                .circle {
                    fill: transparent;
                    stroke: var(--white);
                }

                .line { stroke: var(--white); }
            }
        }
    }

    .back,
    .toggle-menu {
        height: var(--toggle-width);
        transition: opacity .3s var(--ease-out-quad);
        width: var(--toggle-width);
    }

    .toggle-menu {
        align-items: center;
        background: var(--blue);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: relative;

        @include z-index($z-index-wrap);

        &::after {
            @include z-index(1);

            background: var(--white);
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            transform: translate3D(-105%, 0, 1px);
            transition: transform .2s var(--ease-out-quad);
            width: 100%;
        }

        svg {
            @include z-index(2);

            height: 50%;
            transition: transform .1s var(--ease-out-quad);
            width: 50%;
        }

        &.__close:after {
            transform: translate3D(0, 0, 1px);
        }

        @include isCursor {
            &:hover::after {
                transform: translate3D(0, 0, 1px);
            }
        }
    }

    .logo {
        width: var(--logo-width);

        @include z-index($z-index-wrap);

        svg {
            height: 100%;
            width: 100%;
        }
    }
}
