html {
    background: var(--secondary);
    height: 100%;
    width: 100%;
}

body {
    min-height: 100%;
    width: 100%;

    main {
        width: 100%;
    }

    &:not(.__scroll-manual){
        overflow: hidden;

        main {
          position: fixed;
          @include z-index($z-index-wrap);
          height: 100%;
          top:0;
          left:0;
        }
    }

    &.__scroll-manual {
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }
}

ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}

.wrapper-max {
    margin: 0 auto;
    max-width: var(--max-width);
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.icon {
    display: block;

    svg {
        height: 100%;
        width: 100%;
    }
}