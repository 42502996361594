:root {
  --white: #ffffff;
  --whiteRGB: 255, 255, 255;
  --black: #000000;
  --blue: #0000F9;
  --blueRGB: 0, 0, 249;
  --gray: #C4C4C4;

  --primary: var(--black);
  --secondary: var(--white);

  --assertive: #ff00ff;
  --focus: #ff00ff;
  --color-error: #ff0000;
  --color-sucess: #4dc247;
}

.palette-primary {
    --primary: var(--black);
    --secondary: var(--white);
}
