.block-methodology {
    --font-size-big: var(--font-size-xxlarge);
    --font-size: var(--font-size-mid);
    --margin-v: var(--padding-xxxl);
    --padding-top: var(--padding-xxl);
    --padding-bottom: var(--padding-xl);
    --padding-h: var(--padding-xxxxl);

    @media (max-width: $smartphone) {
        --font-size-big: var(--font-size-xxlarge2);
        --font-size: var(--font-size-xlarge);
        --margin-v: 0;
        --padding-top: var(--padding-xxxl);
        --padding-bottom: var(--padding-l);
        --padding-h: var(--padding-s);
    }
}

.block-methodology {
    font-size: var(--font-size);
    padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
    position: relative;

    @include font-sans();

    &__text {
        margin-bottom: var(--padding-m);

        p { margin-top: 0; }
    }

    &__number {
        color: var(--blue);
        font-size: var(--font-size-big);
    }

    &__items {
        max-width: rem(720);
    }

    @media (max-width: $smartphone) {
        &__header {
            margin-bottom: var(--padding-xl);
            text-align: center;

            > span {
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
                top: 0;
                width: rem(130);
            }

            .arrow-desktop { display: none; }
        }

        &__items {
            margin: var(--padding-xl) auto 0;

            li:not(:last-child) {
                margin-bottom: var(--padding-s);
            }
        }
    }

    @media (min-width: $smartphone) {
        &__header {
            margin-bottom: var(--padding-xxl);
            position: relative;

            .arrow-desktop {
                display: block;
                margin-top: var(--padding-m);
                margin-left: var(--padding-xxxl);
                width: rem(530);
                z-index: 1;

                svg {
                    height: auto;
                    width: 100%;
                }
            }

            h2 { margin: 0; }

            .arrow-mobile { display: none; }
        }

        &__content {
            padding-left: 33%;
        }

        &__items {
            margin: var(--padding-xxxxl) auto 0;

            li:not(:last-child) {
                margin-bottom: var(--padding-l);
            }
        }
    }
}