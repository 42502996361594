.tab-slider {
    --font-size: var(--font-size-mid);
    --size: var(--padding-l);

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xlarge);
    }

    @media (max-width: $smartphoneMid) {
        --font-size: var(--font-size-large);
    }

    &.--sm {
        --size: var(--padding-m);
    }
}

.tab-slider {
    background: var(--white);
    border-radius: var(--padding-s);
    font-size: var(--font-size);
    height: var(--size);
    overflow: hidden;
    position: relative;
    text-align: center;

    &::after {
        position: absolute;
        content: '';
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        border: 1px solid var(--black);
        border-radius: var(--padding-s);
        z-index: 5;
    }

    @include font-sans(1.5);

    line-height: var(--size);

    .bg-text { text-decoration: line-through; }

    .text {
        background: var(--blue);
        border-radius: var(--padding-s);
        color: var(--white);
        height: var(--size);
        left: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        transform: translate3d(calc(-100% + var(--size)), 0, 0);
        width: 100%;
        z-index: 2;

        span {
            height: 100%;
            left: 100%;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 3;
        }

        &::after {
            border: 1px solid var(--black);
            border-radius: 50%;
            background: var(--white);
            content: '';
            width: var(--size);
            height: var(--size);
            position: absolute;
            right: 0;
            top: 0;
            z-index: 4;
            box-sizing: border-box;
        }
    }

    @include isCursor() {
        .block-methodology__items & .text.animated {
            transition: transform .8s var(--ease-out-quart);
        }

        .block-methodology__items &:hover .text.animated {
            transform: translate3d(calc(-100% + var(--size) - 2px), 0, 0) !important;
        }
    }
}