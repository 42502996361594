.block-learning-revolutionaries {
    --font-size: var(--font-size-mid);
    --margin-v: var(--padding-xxxl);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-xxxxl);
    --arrows-pos: var(--padding-l);

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xlarge);
        --margin-v: 0;
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-s);
    }
}

.block-learning-revolutionaries {
    color: var(--color);
    margin: var(--margin-v) 0;
    overflow: hidden;

    &__header {
        padding: var(--padding-v) var(--padding-h) 0;
        position: relative;
        text-align: left;
        pointer-events: none;
        width: 100%;

        h2 {
            margin: 0;
            text-align: right;
        }

        svg { position: absolute; }
    }

    &__wrapper {
        overflow: hidden;
        position: relative;
    }

    &__slider {
        display: flex;
        height: 100%;
        width: auto;
    }

    @include font-sans();

    p { margin: 0; }

    .arrow {
        @include z-index($z-index-3D);

        cursor: pointer;
        display: block;
        height: var(--toggle-width);
        position: absolute;
        top: 50%;
        transform: translate3d(0, -50%, #{$z-index-3D + "px"});
        width: var(--toggle-width);

        svg {
            height: 100%;
            width: 100%;

            path { stroke: var(--black); }
        }

        &.arrow-left {
            left: var(--arrows-pos);
        }

        &.arrow-right {
            right: var(--arrows-pos);
        }
    }

    .slide {
        display: inline-flex;
        height: 100%;
        padding: var(--padding-v) var(--padding-h);
        position: relative;
        width: 100vw;

        > * { user-select: none; }

        &__bio {
            font-size: var(--font-size-base);
            margin-bottom: var(--padding-s);

            .name {
                margin-right: var(--padding-xxxs);
                text-transform: uppercase;
            }

            .country {
                color: var(--blue);
                margin-left: var(--padding-xxxs);
            }

            .program {
                @include font-serif-italic(0.95);

                display: block;
                margin-top: var(--padding-xxxs);
            }
        }

        &__image {
            overflow: hidden;
            position: relative;

            .media-holder {
                height: rem(336);
                width: rem(264);
            }
        }

        &__cite {
            font-size: var(--font-size);

            *:not(:last-child) { margin-bottom: var(--padding-s); }
        }
    }

    &.dragging .slide .tint { opacity: .8; }

    @media (max-width: $smartphone) {
        .arrow,
        .slide__bio.desktop,
        .arrow-desktop { display: none; }

        .slide {
            padding: var(--padding-v) var(--padding-xxl) var(--padding-v) var(--padding-h);
            scroll-snap-align: start;

            &__image {
                margin-bottom: var(--padding-l);

                > div { transform: none !important; }
                img { object-fit: cover; }
                .tint { display: none; }
            }
        }

        &__slider {
            overflow: hidden;
            overflow-x: scroll;
            scroll-behavior: smooth;
            scroll-snap-type: x mandatory;
        }

        &__header {
            h2 { padding-left: 10%; }

            svg {
                left: 45%;
                transform: translateX(-50%);
                width: rem(130);
            }
        }
    }

    @media (min-width: $smartphone) {
        .slide__bio.mobile,
        .arrow-mobile { display: none; }

        &__header {
            position: absolute;
            z-index: 2;

            h2 { padding-left: 50%; }

            svg {
                height: auto;
                left: 40%;
                top: 50%;
                width: rem(480);
                z-index: 1;
            }
        }

        &__slider {
            left: 0;
            position: absolute;
            top: 0;
        }

        &__wrapper { height: rem(750); }

        .slide {
            &__image {
                .media-holder {
                    height: rem(630);
                    width: rem(480);
                }

                .tint {
                    height: 100%;
                    background: var(--blue);
                    left: 0;
                    mix-blend-mode: lighten;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    transition: opacity .3s ease-out;
                    width: 100%;
                    z-index: 1;
                }
            }

            &__bio { margin-bottom: var(--padding-xl); }

            &__content {
                bottom: var(--padding-v);
                left: 50%;
                position: absolute;
                width: 28%;
            }
        }
    }
}