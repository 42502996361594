#Sidemenu {
    --bg: var(--blue);
    --color: var(--white);
    --font-size-big: var(--font-size-xxlarge);
    --font-size: var(--font-size-large);
    --padding-top: calc(var(--header-height) + var(--padding-xxxl));
    --padding-bottom: var(--padding-m);
    --padding-h: var(--padding-xxl);

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xlarge);
        --padding-top: calc(var(--header-height) + var(--padding-xl));
        --padding-bottom: var(--padding-s);
        --padding-h: var(--padding-xs);
    }
}

#Sidemenu {
    background-color: var(--bg);
    height: 100vh;
    top: 0;
    position: fixed;
    left: 0;
    width: 100%;

    @include z-index($z-index-3D);
    @include font-sans();

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }

    a {
        @include basic-a();
    }

    span.link { cursor: auto; }

    & > .content {
        height: 100%;
        left: 0;
        padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
        position: absolute;
        top: 0;
        width: 100%;

        @include isTouch() {
            overflow-y: scroll;
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch;
        }

        @include z-index(1);
    }

    .link,
    .link-arrow {
        color: var(--color);
        cursor: pointer;
        display: block;
    }

    .link {
        font-size: var(--font-size-big);
    }

    .link-arrow {
        font-size: var(--font-size);
        font-weight: 100;
    }

    .link-arrow:hover,
    a.link:hover {
        @include font-serif-italic();
    }

    @include isTouch() {
        .link-arrow:hover,
        a.link:hover {
            @include font-sans();

            font-style: normal;
        }
    }

    ul {
        .link {
            height: calc(var(--font-size-big) * var(--line-height-sans));
            overflow: hidden;
            position: relative;

            span {
                display: inline-block;
                position: relative;
            }
        }

        li {
            .sublist {
                margin-top: var(--padding-xs);
                padding-left: var(--padding-xl);

                > *:not(:last-child) { margin-bottom: var(--padding-xs); }
            }
        }
    }

    .contact {
        margin-top: var(--padding-xxxl);
        padding-bottom: var(--padding-xl);

        .link {
            align-items: stretch;
            display: flex;
            width: 100%;

            .text {
                margin-left: var(--padding-s);
            }

            .arrow-icon {
                position: relative;
                flex-grow: 1;
                overflow: hidden;

                svg {
                    height: 100%;
                    position: absolute;
                    right: 0;
                    width: rem(934);
                }
            }
        }
    }

    @media (max-width: $smartphone) {
        .contact {
            margin-top: var(--padding-xxl);
            padding-bottom: var(--padding-l);

            .link .arrow-icon svg { width: 260%; }
        }

        ul li:not(:last-child) { margin-bottom: var(--padding-xs); }
        ul li .sublist  { padding-left: var(--padding-s); }
    }

    @media (max-width: $smartphoneMid) {
        .contact {
            margin-top: var(--padding-xxl);

            .link .arrow-icon svg { width: 260%; }
        }

        ul li:not(:last-child) { margin-bottom: var(--padding-xs); }
        ul li .sublist  { padding-left: var(--padding-s); }
    }

    @media (min-width: $smartphone) {
        .contact {
            .link .text { width: rem(318); }
        }
    }
}
