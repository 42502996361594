.block-compatibility {
    --padding-v: var(--padding-xxl);
    --padding-h: 8%;
    --font-size-big: var(--font-size-large);
    --font-size-small: var(--font-size-mid);

    @media (max-width: $smartphone) {
        --font-size-big: var(--font-size-xxlarge);
        --font-size-small: var(--font-size-large);
        --padding-v: var(--padding-xl);
        --padding-h: var(--padding-s);
    }
}

.block-compatibility {
    padding: var(--padding-v) var(--padding-h);

    @include font-sans();

    &__text {
        @include font-sans-light();

        font-size: var(--font-size-small);
        margin-bottom: var(--padding-l);

        p { margin: 0; }

        em,
        strong {
            @include font-sans();

            font-size: var(--font-size-big);
        }
    }

    @media (min-width: $smartphone) {
        &__text {
            max-width: rem(670);
        }

        &__wrapper {
            display: flex;

            .compatibility-table {
                min-width: calc(50% - (var(--padding-xl) / 2));
                max-width: rem(720);

                &:last-child { margin-left: var(--padding-xl); }
                &:first-child { margin: auto; }
            }
        }
    }
}
