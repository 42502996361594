@keyframes --marquee-100 {
    0% { transform: translateX(0) }
    100% { transform: translateX(-100%) }
}

@keyframes --marquee100 {
    0% { transform: translateX(-100%) }
    100% { transform: translateX(0%) }
}

@keyframes --marquee-50 {
    0% { transform: translate3D(0,0,0) }
    100% { transform: translate3D(-50%,0,0) }
}

@keyframes --marquee50 {
    0% { transform: translate3D(-50%,0,0) }
    100% { transform: translate3D(0,0,0) }
}

@mixin marquee() {
    --space: var(--padding-xxs);
    --animation: 12s;

    display: inline-flex;
    padding-right: var(--space);
    position: relative;
    white-space: nowrap;
    width: auto;

    &:not(.--invert) {
        animation: --marquee-50 var(--animation) infinite linear;
    }

    &.--invert {
        animation: --marquee-50 var(--animation) infinite linear;
    }

    .items {
        display: inline-flex;
        flex-wrap: nowrap;

        li {
            margin-right: var(--space);

            &::after {
                content: '·';
                margin-left: var(--space);
            }
        }

        a {
            color: currentColor;
            text-decoration: none;
        }
    }

    @media (max-width: $smartphone) {
        --animation: 45s;
    }
}

@mixin single-marquee() {
    --space: var(--padding-s);
    --animation: 8s;

    animation: --marquee-50 var(--animation) infinite linear;
    padding-left: var(--space);
    white-space: nowrap;
    width: auto;

    &:after {
        content: attr(data-text);
        padding-left: var(--space);
    }
}


.single-marquee {
    @include single-marquee();
}

.marquee {
    @include marquee();
}

.single-marquee,
.marquee {
    &:hover {
      animation-play-state: paused;
    }

    @include isTouch {
        &:hover {
            animation-play-state: running;
        }
    }
}