#Loader {
    --bg: var(--black);
}

#Loader {
    align-items: center;
    background-color: var(--bg);
    bottom: 0;
    display: flex;
    justify-content: center;
    height: 100%;
    left: 0;
    position: fixed;
    width: 100%;

    &.hidden {
        visibility: hidden;
    }

    span {
        overflow: hidden;
        position: relative;
    }

    svg {
        display: block;
        height: auto;
        position: relative;
        transform: translate3d(0, 100%, 0);
        width: calc(1.25 * var(--logo-width));
    }

    @include z-index($z-index-loader);
}
