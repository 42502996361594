.block-programs {
    --padding-v: var(--padding-xxl);

    @media (max-width: $smartphone) {
        --padding-v: 0;
    }
}

.block-programs {
    overflow: hidden;
    padding: var(--padding-v) 0;

    &__header {
        text-align: center;
    }

    .circle1,
    .shape-left,
    .shape-right,
    .circle2 {
        display: block;
        pointer-events: none;
        position: absolute;
    }

    .circle1 { @include z-index($z-index-bg); }
    .shape-left,
    .shape-right { @include z-index($z-index-3D); }

    .arrow-desktop {
        height: rem(222);
        margin: 0 auto;
        width: rem(100);
    }

    @media (max-width: $smartphone) {
        &__header {
            .arrow-mobile { margin-bottom: var(--padding-l); }
            .arrow-desktop { display: none; }
        }

        .program-card:not(:last-child) { margin-bottom: var(--padding-l); }

        .circle1,
        .shape-left,
        .shape-right,
        .circle2 {
            display: none;
        }
    }

    @media (min-width: $smartphone) {
        &__header {
            h2 { margin: 0 0 var(--padding-s); }
            .arrow-mobile { display: none; }
        }

        &__content {
            display: grid;
            gap: var(--padding-xs);
            grid-template-columns: repeat(2, 1fr);
            padding-bottom: 0;
            padding-top: var(--padding-xxxl);
            position: relative;

            .program-card:nth-child(2n) { transform: translateY(var(--padding-xxxl)); }
        }

        .circle1 {
            left: -11%;
            top: 0%;
        }

        .shape-left {
            top: 12%;
            right: -2%;
        }

        .shape-right {
            top: 45%;
            left: -5%;
        }

        .circle2 {
            @include z-index($z-index-3D);

            bottom: 10%;
            right: -10%;
        }
    }
}
