.block-newsletter {
    --font-size: var(--font-size-base-l);
    --font-size-small: var(--font-size-base);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-xxxxl);
    --input-size: var(--padding-l);
    --img-width: #{330px / 16px * 1rem};

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xlarge);
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-s);
        --input-size: var(--padding-xxl);
        --img-width: #{166px / 16px * 1rem};
    }
}

.block-newsletter {
    font-size: var(--font-size);
    overflow: hidden;
    padding: var(--padding-v) var(--padding-h);

    @include font-sans();

    &__header {
        p { margin: 0; }

        .media-holder {
            padding-top: calc(var(--img-width) * var(--aspect));
            width: var(--img-width);
        }
    }

    &__footer {
        a:hover {
            text-decoration: none;

            @include isTouch {
                text-decoration: underline;
            }
        }
    }

    &__form {
        input,
        button,
        label {
            border-radius: 0;
            box-shadow: none;
            height: var(--input-size);
            line-height: var(--input-size);
            padding: 0 var(--padding-xs);
        }

        input {
            background: transparent;
            color: var(--black);
            width: 100%;
        }

        button {
            background: var(--blue);
            color: var(--white);

            &:hover {
                background: var(--white);
                color: var(--blue);

                @include isTouch() {
                    background: var(--blue);
                    color: var(--white);
                }
            }
        }

        fieldset {
            border: 0;
            outline: none;
            margin: 0;
            padding: 0;
            position: relative;
        }

        ::placeholder,
        :-ms-input-placeholder,
        ::-ms-input-placeholder {
            color: var(--gray);
        }

        .hidden { display: none; }
    }

    @media (max-width: $smartphoneMid) {
        &__header {
            padding: var(--padding-l) var(--padding-xs);
        }
    }

    @media (max-width: $smartphone) {
        &__header {
            border: 2px solid var(--blue);
            border-bottom: 0;
            padding: var(--padding-l) var(--padding-s);
            text-align: center;

            .media-holder {
                margin: 0 auto var(--padding-l);
            }
        }

        &__form {
            input,
            button,
            label {
                border: 2px solid var(--blue);
                text-align: center;
                width: 100%;
            }
        }

        &__footer { display: none; }
    }

    @media (min-width: $smartphone) {
      &__wrapper {
            background: linear-gradient(90deg, transparent, transparent 66.66% , var(--blue) 66.66%);
            border: 1px solid var(--blue);
            padding: var(--padding-xl);
        }

        &__header {
            margin-bottom: var(--padding-s);

            > div { max-width: rem(485); }
            .media-holder { margin-bottom: var(--padding-s); }
        }

        &__form form {
            display: flex;
            width: 100%;

            fieldset { flex-grow: 1; }
            button { width: rem(200); }

            input,
            button,
            label {
                border: 1px solid var(--black);
            }
        }

        &__footer {
            font-size: var(--font-size-small);
            margin-top: var(--padding-xxs);
            text-align: right;

            a { color: var(--white); }
        }
    }
}