:root {
    --font-sans: 'Archivo', sans-serif;
    // --font-sans-bold: 'Petrona', sans-serif;
    --font-serif-italic: 'Petrona', sans-serif;

    --font-size-xxxlarge: #{(143px / 16px) * 1rem};
    --font-size-xxlarge: #{(96px / 16px) * 1rem};
    --font-size-xxlarge1: #{(55px / 16px) * 1rem};
    --font-size-xlarge: #{(79px / 16px) * 1rem};
    --font-size-large: #{(63px / 16px) * 1rem};
    --font-size-mid-l: #{(37px / 16px) * 1rem};
    --font-size-mid: #{(33px / 16px) * 1rem};
    --font-size-base-l: #{(26px / 16px) * 1rem};
    --font-size-base: #{(15px / 16px) * 1rem};
    --font-size-small: #{(15px / 16px) * 1rem};

    --line-height-sans: 1.14;
    --letter-spacing: -2%;

    font-size: 1.1111vw;

    @media (max-width: $smartphone) {
        font-size: 16px;

        --font-size-xxxlarge: #{(105px / 16px) * 1rem};
        --font-size-xxlarge2: #{(79px / 16px) * 1rem};
        --font-size-xxlarge1: #{(55px / 16px) * 1rem};
        --font-size-xxlarge: #{(35px / 16px) * 1rem};
        --font-size-xlarge: #{(27px / 16px) * 1rem};
        --font-size-lg: #{(23px / 16px) * 1rem};
        --font-size-large: #{(21px / 16px) * 1rem};
        --font-size-mid: #{(18px / 16px) * 1rem};
        --font-size-small: #{(11px / 16px) * 1rem};
    }

    @media (max-width: $smartphoneMid) {
        --font-size-xxlarge: #{(30px / 16px) * 1rem};
        --font-size-xlarge: #{(20px / 16px) * 1rem};
    }
}

@mixin font-sans-light($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: 300;

    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-sans($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: normal;

    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-sans-regular($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: 500;

    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-sans-bold($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: 700;

    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-serif-italic($line-height: 0) {
    font-family: var(--font-serif-italic);
    font-weight: 300;
    font-style: italic;

    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-sans-italic($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: normal;
    font-style: italic;

    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}

.display-sans {
    @include font-sans();

    font-size: var(--font-size-xxlarge);
}

.text1-sans {
    @include font-sans-light();

    font-size: var(--font-size-xlarge);
    letter-spacing: var(--letter-spacing);
}

.text2-sans {
    @include font-sans-light();

    font-size: var(--font-size-large);
    letter-spacing: var(--letter-spacing);
}

.text3-sans {
    font-size: var(--font-size-mid);
}

@media (max-width: $smartphone) {
    .text1-sans {
        font-size: var(--font-size-xxlarge);
    }
}