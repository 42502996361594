.compatibility-table {
    --font-size-big: #{(55px / 16px) * 1rem};
    --font-size-larg: var(--font-size-mid);
    --font-size: var(--font-size-mid);
    --font-size-sm: var(--font-size-base);

    @media (max-width: $smartphone) {
        --font-size-big: var(--font-size-xxlarge);
        --font-size-larg: var(--font-size-lg);
        --font-size: var(--font-size-large);
        --font-size-sm: #{(11px / 16px) * 1rem};
    }
}

.compatibility-table {
    &:not(:last-child) { margin-bottom: var(--padding-l); }

    &__logo {
        border: 1px solid var(--black);
        height: var(--padding-xl);
        padding: var(--padding-xxs) var(--padding-xs);

        .media-holder {
            height: 100%;
            padding-top: 0;
            text-align: left;
            width: auto;

            img {
                object-fit: contain;
                object-position: left;
            }
        }
    }

    &__text1,
    &__text2 {
        border: 1px solid var(--black);
        border-bottom: 0;
        padding: var(--padding-xxs) var(--padding-xs);
    }

    &__text1 { font-size: var(--font-size-sm); }
    &__text2 { font-size: var(--font-size); }

    &__options {
        font-size: var(--font-size-larg);

        li {
            border: 1px solid var(--black);

            &:not(:last-child) { border-bottom: 0; }
        }
    }

    &__option {
        background: transparent;
        padding: var(--padding-xxs) var(--padding-xs) var(--padding-xxs) var(--padding-l);
        position: relative;
        text-align: left;
        width: 100%;

        
        &::before {
            background: var(--white);
            border-radius: 50%;
            content: '';
            left: var(--padding-xxs);
            position: absolute;
            top: 50%;
            box-sizing: border-box;
        }

        /*&::after {
            border: 2px solid var(--blue);
            height: rem(18);
            margin-left: rem(3);
            opacity: 0;
            transform: translate3d(0, -50%, 2px);
            width: rem(18);
            z-index: 2;
        }*/

        &::before {
            border: 1px solid var(--black);
            height: var(--padding-s);
            transform: translate3d(0, -50%, 1px);
            width: var(--padding-s);
            z-index: 1;
        }

        &:hover {
            background: var(--blue);
            color: var(--white);

           // &::after { opacity: 1; }
            &::before { border: 1px solid var(--white); }
        }

        @include isTouch() {
            &:hover {
                background: transparent;
                color: var(--black);

               // &::after { opacity: 0; }
                &::before { border: 1px solid var(--black); }
            }
        }

        &.active {
            background: var(--blue);
            color: var(--white);

            /* &::after {
                background: var(--blue);
                opacity: 1;
            } */

            &::before { 
                border: calc(var(--padding-s) * .3) solid var(--white); 
                background: var(--blue);
            }
        }
    }

    &__result {
        display: flex;
        justify-content: flex-end;

        @include font-sans-regular();

        > div {
            border: 1px solid var(--black);
            border-top: 0;
            font-size: var(--font-size-big);
            min-width: rem(280);
            padding: var(--padding-xxs) var(--padding-xs);
            text-align: right;
        }
    }

    &__link {
        display: flex;
        justify-content: flex-end;
        font-size: var(--font-size-larg);
        padding: var(--padding-xxs) 0;

        a {
            align-items: center;
            color: var(--black);
            display: flex;
            text-decoration: none;

            svg {
                height: auto;
                width: rem(56);
            }

            span {
                display: block;
                margin-left: var(--padding-s);
            }
        }
    }

    @media (min-width: $smartphone) {
        &__logo {
            padding: var(--padding-xs);
        }

        &__option {
            padding: var(--padding-xxs) var(--padding-xs) var(--padding-xxs) var(--padding-xxl);

            &::after {
                height: rem(23);
                margin-left: rem(5);
                width: rem(23);
            }

            &::before {
                height: var(--padding-m);
                width: var(--padding-m);
            }

            &::after,
            &::before { left: var(--padding-s); }
        }

        &__result > div { min-width: rem(490); }
    }
}