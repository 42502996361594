.program-billboard {
    --font-size-big: var(--font-size-xxlarge);
    --font-size: var(--font-size-large);
    --padding-h: var(--padding-l);
    --padding-top: calc(var(--padding-l) + var(--header-height));
    --padding-bottom: var(--padding-l);

    @media (max-width: $smartphone) {
        --font-size-big: var(--font-size-xxlarge1);
        --font-size: var(--font-size-xlarge);
        --padding-h: var(--padding-s);
        --padding-top: calc(var(--header-height) + var(--padding-s));
        --padding-bottom: var(--padding-m);
    }

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xlarge);
    }
}

.program-billboard {
    background: var(--black);
    color: var(--white);

    &__content {
        padding: var(--padding-top) var(--padding-h) var(--padding-bottom);

        h1 {
            font-size: var(--font-size-big);
            margin: 0;

            > div {
                height: calc(var(--font-size-big) * 1.05);
                overflow: hidden;

                span {
                    display: block;
                    position: relative;
                }
            }

            @include font-sans(1);
        }

        .subtitle {
            display: flex;
            font-size: var(--font-size);
            margin: 0;

            .arrow > span {
                display: block;
                position: relative;
            }

            @include font-sans-light();
        }
    }

    @media (max-width: $smartphone) {
        h1 {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin-bottom: var(--padding-xs);
            min-height: rem(220);
        }

        .subtitle {
            .--desktop { display: none; }

            svg {
                margin-right: var(--padding-xs);
                min-width: rem(145);
            }
        }

        &__image {
            overflow: hidden;
            padding-bottom: 80%;
            position: relative;

            &-wrapper,
            .media-holder {
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }

            .media-holder img { object-fit: cover; }
        }
    }

    @media (max-width: $smartphone) {
        &__image-wrapper {
            transform: none !important;

            > div {
                height: 100%;
            }
        }
    }

    @media (min-width: $smartphone) {
        overflow: hidden;
        padding-bottom: 50%;

        &__content {
            align-items: flex-end;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            @include z-index($z-index-3D);
        }

        &__image {
            @include z-index($z-index-bg);
        }

        &__content,
        &__image {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        &__text {
            padding: 0 rem(83);
            width: 100%;

            h1 {
                margin-bottom: var(--padding-xxs);
                max-width: rem(485);
            }

            .subtitle {
                .--mobile { display: none; }

                .text {
                    max-width: rem(495);
                    min-width: rem(495);
                }

                .arrow {
                    flex-grow: 1;
                    margin-right: var(--padding-xxs);
                    margin-top: var(--padding-xs);
                    overflow: hidden;
                    position: relative;

                    svg {
                        height: auto;
                        position: absolute;
                        right: 0;
                    }
                }
            }
        }
    }
}
