.block-slider {
    --bg: var(--black);
    --color: var(--white);
    --font-size-big: var(--font-size-xxxlarge);
    --font-size: var(--font-size-xxlarge);
    --margin-v: var(--padding-xxxl);
    --padding-v: 0; //var(--padding-xxxl);
    --padding-h: 0;

    @media (max-width: $smartphone) {
        --font-size-big: var(--font-size-xxlarge2);
        --margin-v: 0;
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-s);
    }
}

.block-slider {
    background: var(--bg);
    color: var(--color);
    overflow: hidden;
    padding: var(--padding-v) var(--padding-h);
    margin: var(--margin-v) 0;

    @include font-sans-light();

    p { margin: 0; }

    .number {
        font-size: var(--font-size-big);

        @include font-sans-bold(1);
    }

    .text {
        font-size: var(--font-size);

        @include font-sans-light();
    }

    .slide {
        &__image { position: relative; }
        img { object-fit: cover; }
    }

    &__scroll-indicator {
        @include z-index($z-index-wrap);

        bottom: var(--padding-xl);
        height: var(--toggle-width);
        left: 50%;
        position: absolute;
        transform: translate3d(-50%, 0, #{$z-index-wrap + 'px'});
        width: var(--toggle-width);

        .back {
            height: 100%;
            width: 100%;

            svg {
                height: 100%;
                width: 100%;

                path { stroke: var(--white); }
            }
        }
    }

    @media (max-width: $smartphone) {
        &__scroll-indicator { display: none; }

        .slide {
            &:not(:last-child) {
                margin-bottom: var(--padding-l);
            }

            &__image { margin-bottom: var(--padding-m); }
        }
    }

    @media (min-width: $smartphone) {
        height: var(--height);
        position: relative;

        > div {
            height: 100vh;
            position: relative;
        }

        &__wrapper {
            display: flex;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: auto;

            .slide {
                display: inline-flex;
                height: 100%;
                padding: var(--padding-xxxl) rem(150) var(--padding-xxl) rem(202);
                width: 100vw;

                > div { position: relative; }

                &__content {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: flex-end;
                    position: relative;
                    width: 90%;
                    z-index: 1;
                }

                &__image {
                    overflow: hidden;
                    position: absolute;
                    right: 0;
                    top: 0;

                    .media-holder {
                        height: rem(480);
                        transform: scale3d(1.5, 1.5, 1);
                        width: rem(720);
                    }
                }
            }
        }
    }
}