:root {
  --y-header: 0;

  --padding-xxxxxl: #{(240px / 16px) * 1rem};
  --padding-xxxxl: #{(180px / 16px) * 1rem};
  --padding-xxxl: #{(120px / 16px) * 1rem};
  --padding-xxl: #{(90px / 16px) * 1rem};
  --padding-xl: #{(60px / 16px) * 1rem};
  --padding-l: #{(48px / 16px) * 1rem};
  --padding-m: #{(32px / 16px) * 1rem};
  --padding-s: #{(24px / 16px) * 1rem};
  --padding-xs: #{(16px / 16px) * 1rem};
  --padding-xxs: #{(8px / 16px) * 1rem};
  --padding-xxxs: #{(4px / 16px) * 1rem};

  --header-height: #{(90px / 16px) * 1rem};
  --toggle-width: #{(64px / 16px) * 1rem};
  --logo-width: #{240px / 16px * 1rem};

  --max-width: calc(1080px + var(--padding-l));
}

@media (max-width: $smartphone) {
    :root {
        --header-height:#{(72px / 16px) * 1rem};
        --toggle-width:#{(48px / 16px) * 1rem};
        --logo-width: #{161px / 16px * 1rem};
    }
}
